.App {
  text-align: center;
}

.select__wrapper {
  width: 300px;
  margin: 0 auto;
  margin-top: 20px;
}

.select__input {
  width: 100%;
  height: 38px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.select__audio-item--none {
  display: none !important;
}

.select__input--item {
  height: 15px;
  /* margin: 10px; */
  padding: 10px;
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.select__input--item-active {
  background: red;
}

.select__title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 44px;
  color: #2C2C2C;
  margin-bottom: 10px;
  margin-top: 10px;
}

.select__button {
  margin-top: 20px;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  width: 100%;
  height: 38px;
  cursor: pointer;
  font-weight: 700;
}

.select__audio-list {
  width: 500px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.select__audio-list li {
  margin-bottom: 10px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.input-file {
  position: relative;
  display: inline-block;
}

.input-file span {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  vertical-align: middle;
  color: #000;
  text-align: center;
  border-radius: 4px;
  background-color: #419152;
  line-height: 22px;
  height: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: none;
  margin: 0;
  transition: background-color 0.2s;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100%;
}

.input-file input[type=file] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

.input-file {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-file__button {
  width: 100%;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
}

.input-file__button-none {
  display: none !important;
}

.select__wrapper--two {
  margin-top: 0px;
}

IMG { 
  WIDTH: 100PX;
  DISPLAY: FLEX;
  JUSTIFY-CONTENT: CENTER;
  ALIGN-ITEMS: CENTER;
  MARGIN: 0 AUTO;
  MARGIN-TOP: 20PX;
}

.dot1 {
  animation: visibility 3s linear infinite;
 }
 
 @keyframes visibility {
  0% {
  opacity: 1;
  }
  65% {
  opacity: 1;
  }
  66% {
  opacity: 0;
  }
  100% {
  opacity: 0;
  }
 }
 
 .dot2 {
  animation: visibility2 3s linear infinite;
 }
 
 @keyframes visibility2 {
  0% {
   opacity: 0;
  }
  21% {
   opacity: 0;
  }
  22% {
   opacity: 1;
  }
  65% {
   opacity: 1;
  }
  66% {
   opacity: 0;
  }
  100% {
   opacity: 0;
  }
 }
 
 .dot3 {
  animation: visibility3 3s linear infinite;
 }
 
 @keyframes visibility3 {
  0% {
   opacity: 0;
  }
  43% {
   opacity: 0;
  }
  44% {
   opacity: 1;
  }
  65% {
   opacity: 1;
  }
  66% {
   opacity: 0;
  }
  100% {
   opacity: 0;
  }
 }

 .processing__wrapper--text {
   margin-bottom: 20px;
 }

 .wrapper__logo--index {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
 }